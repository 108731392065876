<template>
  <base-layout-two :page-title="user.fullname" :page-default-back-link="`/users/${$route.params.id}`">
    <!-- Header -->
    <div class="padding-8">
      <base-card :title="activityTitle">
        <v-form @submit="createActivity">
          <!-- Date -->
          <base-input label-text="Date *">
            <v-field name="datetime" v-slot="{ field }" v-model="activity.datetime" :rules="requiredString">
              <ion-input name="date" v-bind="field" type="datetime-local" :max="now">
              </ion-input>
            </v-field>
          </base-input>
          <v-error-message name="datetime" class="error-message">
          </v-error-message>

          <!-- Option 1 -->
          <base-input label-text="Formula or Breastmilk" v-if="showOption1">
            <v-field name="option_1" v-slot="{ field }" v-model="activity.option_1" :rules="requiredString">
              <select v-bind="field" class="regular-select">
                <option value="formula">Formula</option>
                <option value="breastmilk">Breastmilk</option>
              </select>
            </v-field>
          </base-input>
          <v-error-message name="option_1" class="error-message">
          </v-error-message>

          <!-- Measurement 1 -->
          <base-input :label-text="`Amount (${this.activity.units_1})*`" v-if="showMeasurement1">
            <v-field name="measurement_1" v-slot="{ field }" v-model="activity.measurement_1"
              :rules="(activity.type === 'baby_feeding') ? null : requiredNumber">
              <ion-input name="measurement_1" v-bind="field" type="number">
              </ion-input>
            </v-field>
          </base-input>
          <v-error-message name="measurement_1" class="error-message">
          </v-error-message>

          <!-- Units 1 -->
          <base-input label-text="Units *" v-if="showUnits1">
            <v-field name="units_1" v-slot="{ field }" v-model="activity.units_1" :rules="requiredString">
              <select v-bind="field" class="regular-select" disabled>
                <option value="ml">ml</option>
              </select>
            </v-field>
          </base-input>
          <v-error-message name="units_1" class="error-message">
          </v-error-message>

          <base-input label-text="Notes">
            <v-field name="notes" v-slot="{ field }" v-model="activity.notes">
              <ion-textarea name="notes" :auto-grow="true" rows="1" v-bind="field" placeholder="" autocapitalize
                autocorrect></ion-textarea>
            </v-field>
          </base-input>
          <v-error-message name="notes" class="error-message"></v-error-message>

          <ion-button type="submit" expand="block">Save</ion-button>
        </v-form>
      </base-card>
    </div>
  </base-layout-two>
</template>

<script>
import { IonInput, IonButton, IonTextarea } from "@ionic/vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { mapActions } from "vuex";
import { format } from "date-fns";

export default {
  components: {
    IonInput,
    IonButton,
    IonTextarea,
    VForm: Form,
    VField: Field,
    VErrorMessage: ErrorMessage,
  },

  data() {
    return {
      user: {},
      activity: {
        user_id: this.$route.params.id,
        created_by_id: this.$store.getters.authUser.id,
        type: this.$route.query.type,
        scanned: (this.$route.query.scanned) ? 1 : 0,
        datetime: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
      },
      requiredString: yup.string().required("This is required"),
      requiredNumber: yup.number().required("This is required"),
      now: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
    };
  },

  computed: {
    activityTitle() {
      if (this.$route.query.type === "sitz_bath")
        return "Sitz Bath";
      else if (this.$route.query.type === "foot_bath")
        return "Foot Bath";
      else if (this.$route.query.type === "breast_massage")
        return "Breast Massage";
      else if (this.$route.query.type === "lactation_assistance")
        return "Lactation Assistance";
      else if (this.$route.query.type === "pump_delivered")
        return "Delivered Breast Pump";
      else if (this.$route.query.type === "pump_retrieved")
        return "Retreived Breast Pump";
      else if (this.$route.query.type === "baby_in_guest_room")
        return "Baby in Room";
      else if (this.$route.query.type === "baby_in_nursery")
        return "Baby in Nursery";
      else if (this.$route.query.type === "baby_feeding")
        return "Feeding";
      else if (this.$route.query.type === "baby_bottle_prep")
        return "Bottle Prep";
      else if (this.$route.query.type === "breastmilk_received")
        return "Collected Breastmilk";
      else if (this.$route.query.type === "baby_bath")
        return "Bath";
      else if (this.$route.query.type === "baby_diaper_wet")
        return "Diaper (Wet)";
      else if (this.$route.query.type === "baby_diaper_poop")
        return "Diaper (Poop)";
      else if (this.$route.query.type === "baby_diaper_empty")
        return "Diaper (Empty)";
      else if (this.$route.query.type === "sales_info_session_zoom")
        return "Info Session (Zoom)";
      else if (this.$route.query.type === "sales_ca_call_zoom")
        return "1:1 CA Call (Zoom or Phone)";
      else if (this.$route.query.type === "sales_tour_onsite")
        return "Tour (Onsite)";
      else return "Activity";
    },

    showOption1() {
      if (this.activity.type === "baby_feeding") return true
      else if (this.activity.type === "baby_bottle_prep") return true
      return false;
    },

    showMeasurement1() {
      if (
        this.activity.type === "baby_feeding" ||
        this.activity.type === "breastmilk_received" ||
        this.activity.type === "baby_bottle_prep"
      )
        return true;
      return false;
    },

    showUnits1() {
      if (
        this.activity.type === "baby_feeding" ||
        this.activity.type === "breastmilk_received" ||
        this.activity.type === "baby_bottle_prep"
      )
        return true;
      return false;
    },
  },

  ionViewWillEnter() {
    this.fetchUser();

    this.activity.type = this.$route.query.type
    this.activity.option_1 = this.$route.query.option_1
    this.activity.units_1 = this.$route.query.units_1
    this.activity.measurement_1 = this.$route.query.measurement_1

    // Set defaults if Feeding
    if (this.$route.query.type === "baby_feeding") {
      this.activity.units_1 = "ml";
    }
    else if (this.$route.query.type === "baby_bottle_prep") {
      this.activity.units_1 = "ml";
    }
    else if (this.$route.query.type === "breastmilk_received") {
      this.activity.option_1 = "breastmilk";
      this.activity.units_1 = "ml";
    }
  },

  methods: {
    ...mapActions(["loadToast", "setShowLoading"]),

    async fetchUser() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/users/${this.$route.params.id}`)
        .then((response) => {
          this.user = response.data.user;
        })
        .catch((error) => { console.log(error) });
    },

    async createActivity() {
      const config = {
        method: "post",
        url: `${process.env.VUE_APP_API}/activities`,
        data: { ...this.activity },
      };

      // Fix  Date
      if (config.data.datetime)
        config.data.datetime = new Date(config.data.datetime)
          .toISOString()
          .replace("T", " ")
          .replace("Z", "");

      // Show a network loading indicator
      this.setShowLoading(true);

      await this.axios(config)
        .then(() => {
          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({
            message: "Success",
            color: "secondary",
          });

          this.$router.replace(`/users/${this.$route.params.id}`);
        })
        .catch((error) => {
          console.log(error);

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Something went wrong", color: "danger" });
        });
    },
  },
};
</script>